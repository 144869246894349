<template>
  <div style="position: relative">
    <el-backtop target=".el-main"></el-backtop>

    <Breadcrumb :Breadcrumb="BreadcrumbCon"></Breadcrumb>

    <div class="search">
      <el-select
        v-model="valueArea"
        filterable
        clearable
        placeholder="请选择区号(可搜索关键字)"
        style="width: 240px; margin-left: 0"
        v-if="internationType"
      >
        <el-option
          v-for="item in options"
          :key="item.name"
          :label="item.name + item.tel"
          :value="item.tel"
        >
        </el-option>
      </el-select>
      <el-input
        v-model="phone"
        width="200"
        placeholder="请输入手机号"
      ></el-input>

      <el-select v-model="status" placeholder="请选择订单状态">
        <el-option
          v-for="item in optionsStatus"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-button type="primary" @click="searchList">查询</el-button>
      <el-button type="info" @click="reset">重置</el-button>
    </div>

    <div class="table_con">
      <el-table
        :data="tableData"
        style="width: 100%"
        border
        :header-cell-style="{ background: '#e5e5e5', color: '#4b4b4b' }"
      >
        <el-table-column type="index" label="序号" width="80" align="center">
          <template slot-scope="scope">
            <span>{{ scope.$index + (currentPage - 1) * pageSize + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="iconUrl" label="商品图片" width="130">
          <template slot-scope="scope">
            <el-image
              style="width: 100%; height: 100%"
              :src="scope.row.iconUrl"
              :preview-src-list="[scope.row.iconUrl]"
            >
            </el-image>
          </template>
        </el-table-column>
        <el-table-column
          prop="goodsName"
          label="商品名称"
          align="center"
          width="160"
        >
        </el-table-column>
        <el-table-column prop="skuPrice" label="商品价格"> </el-table-column>
        <el-table-column prop="goodsNum" label="商品数量"> </el-table-column>
        <el-table-column
          prop="skuAttr"
          label="商品规格"
          align="center"
          width="200"
        >
        </el-table-column>
        <el-table-column prop="orderIdcopy" label="订单编号"> </el-table-column>
        <el-table-column prop="phone" label="手机号" align="center" width="130">
        </el-table-column>
        <el-table-column
          prop="realName"
          label="用户名"
          align="center"
          width="130"
        >
        </el-table-column>
        <el-table-column
          prop="amount"
          label="退款金额"
          align="center"
          width="130"
        >
        </el-table-column>
        <el-table-column
          prop="txid"
          label="交易hash"
          width="280"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="wallet"
          label="交易钱包"
          width="280"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="reason"
          label="原因(通过则为交易hash)"
          width="200"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="refundStatus"
          label="退款状态"
          align="center"
          width="130"
          fixed="right"
        >
          <template slot-scope="scope">
            <div>{{ scope.row.refundStatus }}</div>
            <el-button
              size="mini"
              type="text"
              style="color: #f56c6c"
              @click="handleClick(scope.row)"
              v-if="scope.row.refundStatus == '待审核'"
              >退款</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <Pagination
        v-show="pageLength >= 0"
        :total="pageLength"
        :limit.sync="pageSize"
        @pagination="handlePageChange"
        :page-sizes="pageSizes"
      />
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/BreadCrumb";
import Pagination from "@/components/Pagination";

import { areaCodeData } from "@/util/areaCodeData";

import { reqUrl } from "@/api/apiUrl";
import { request } from "@/api/request";
import { showLoading, hideLoading } from "@/util/loading";
export default {
  inject: ["reload"],
  components: { Breadcrumb, Pagination },
  data() {
    return {
      BreadcrumbCon: [
        {
          name: "趣宝商城",
        },
        {
          name: "趣宝盒预售",
        },
        {
          name: "退款订单列表",
        },
      ], // 面包屑数据

      tableData: [],
      tableDataSuccess: [],

      option: [],
      valueArea: "",
      phone: "",
      status: "",
      internationType: false,
      role: "",
      phoneAreaROLE: "",

      optionsStatus: [
        {
          value: "1",
          label: "待审核",
        },
        {
          value: "2",
          label: "审核通过",
        },
        {
          value: "3",
          label: "审核拒绝",
        },
      ],

      // 分页数据
      currentPage: 1, // 当前页
      pageLength: 0,
      pageSize: 10, //要传过去的数据 每页多少条数据
      pageSizes: [10, 50, 100, 200, 500, 1000],
    };
  },

  mounted() {
    this.options = areaCodeData;
    this.role = localStorage.getItem("ROLE");

    var strNation = this.role.includes("ROLE_nation");
    if (strNation) {
      this.internationType = true;
    }
    this.phoneAreaROLE = localStorage.getItem("phoneArea");

    showLoading();
    const opt = {
      url: reqUrl.presaleOrderGetRefundList,
      method: "POST",
      params: JSON.stringify({
        refundStatus: "",
        phone: "",
        phoneArea: "",
        pageNum: "",
        pageSize: "",
      }),
      resFunc: (res) => {
        console.log(res.data);
        if (res.data.code != 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
          hideLoading();
        } else if (res.data.code == 0) {
          hideLoading();

          this.dataList(res.data.data.list);
          this.pageLength = res.data.data.total;
          this.pageSize = res.data.data.pageSize;
        }
        hideLoading();
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
        hideLoading();
        console.log(err, "err1");
      },
    };
    request(opt);
  },

  methods: {
    searchList() {
      showLoading();
      const opt = {
        url: reqUrl.presaleOrderGetRefundList,
        method: "POST",
        params: JSON.stringify({
          refundStatus: this.status,
          phone: this.phone,
          phoneArea: this.valueArea || this.phoneAreaROLE,
          pageNum: "",
          pageSize: "",
        }),
        resFunc: (res) => {
          console.log(res.data);
          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
            hideLoading();
          } else if (res.data.code == 0) {
            hideLoading();

            this.dataList(res.data.data.list);
            this.pageLength = res.data.data.total;
            this.pageSize = res.data.data.pageSize;
          }
          hideLoading();
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          hideLoading();
          console.log(err, "err1");
        },
      };
      request(opt);
    },

    goDetail(row) {
      this.$router.push("/YSOrderDetail/complete");
      localStorage.setItem("orderYSDetail", JSON.stringify(row));
    },

    handleClick(row) {
      console.log(row);
      this.$confirm("您确定要执行此操作吗?", "提示", {
        distinguishCancelAndClose: true,
        confirmButtonText: "审核通过",
        cancelButtonText: "审核拒绝",
        type: "warning",
      })
        .then(() => {
          this.$confirm("您确定要执行此操作吗?", "提示", {
            distinguishCancelAndClose: true,
            confirmButtonText: "确认",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              const opt = {
                url: reqUrl.examineAuditBoxRefund,
                method: "POST",
                params: JSON.stringify({
                  id: row.refundId,
                  status: 2,
                  auditor: localStorage.getItem("userName"),
                }),
                resFunc: (res) => {
                  console.log(res.data);
                  if (res.data.code == 0) {
                    this.$message({
                      type: "success",
                      message: "操作成功!",
                    });
                    this.refresh();
                  } else if (res.data.code != 0) {
                    this.$notify.error({
                      title: "错误",
                      message: res.data.message,
                    });
                    hideLoading();
                  }
                },
                errFunc: (err) => {
                  this.$notify.error({
                    title: "错误",
                    message: err,
                  });
                  console.log(err, "err1");
                },
              };
              request(opt);
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消操作",
              });
            });
        })
        .catch((action) => {
          if (action == "cancel") {
            this.$prompt("请输入拒绝原因", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              inputPattern: /^.+$/,
              inputErrorMessage: "请输入拒绝原因",
            })
              .then(({ value }) => {
                const opt = {
                  url: reqUrl.examineAuditBoxRefund,
                  method: "POST",
                  params: JSON.stringify({
                    id: row.refundId,
                    status: 3,
                    reason: value,
                    auditor: localStorage.getItem("userName"),
                  }),
                  resFunc: (res) => {
                    console.log(res.data);
                    if (res.data.code == 0) {
                      this.$message({
                        type: "success",
                        message: "操作成功!",
                      });
                      this.refresh();
                    } else if (res.data.code != 0) {
                      this.$notify.error({
                        title: "错误",
                        message: res.data.message,
                      });
                      hideLoading();
                    }
                  },
                  errFunc: (err) => {
                    this.$notify.error({
                      title: "错误",
                      message: err,
                    });
                    console.log(err, "err1");
                  },
                };
                request(opt);
              })
              .catch(() => {
                this.$message({
                  type: "info",
                  message: "已取消操作",
                });
              });
          } else if (action == "close") {
            this.$msgbox.close();
          }
        });
    },

    handlePageChange(data) {
      showLoading();
      const opt = {
        url: reqUrl.presaleOrderGetRefundList,
        method: "POST",
        params: JSON.stringify({
          pageNum: data.pageIndex,
          pageSize: data.pageSize,
        }),
        resFunc: (res) => {
          console.log(res.data);
          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
            hideLoading();
          } else if (res.data.code == 0) {
            hideLoading();
            this.dataList(res.data.data.list);

            this.pageLength = res.data.data.total;
            this.pageSize = res.data.data.pageSize;
            this.currentPage = data.pageIndex;
          }
          hideLoading();
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          hideLoading();
          console.log(err, "err1");
        },
      };
      request(opt);
    },

    dataList(data) {
      let arrList = [];
      data.map((item) => {
        const { orderId } = item.mallOrderListResponse.mallOrderInfo;
        const { id } = item.presaleRefundVO;

        let obj = {};
        obj = {
          ...item.mallOrderListResponse.goodsInfo,
          ...item.mallOrderListResponse.mallOrderInfo,
          ...item.mallOrderListResponse.payOrderInfo,
          ...item.mallOrderListResponse.skuInfo,
          ...item.userInfoDTO,
          ...item.presaleRefundVO,
        };
        obj.orderIdcopy = orderId;
        obj.refundId = id;

        arrList.push(obj);
      });
      this.tableData = arrList;

      arrList.map((item) => {
        if (item.refundStatus == 1) {
          item.refundStatus = "待审核";
        } else if (item.refundStatus == 2) {
          item.refundStatus = "审核通过";
        } else if (item.refundStatus == 3) {
          item.refundStatus = "审核拒绝";
        }

        item.createTime = new Date(item.createTime).toLocaleString();
      });
    },

    reset() {
      this.refresh();
    },

    // 刷新
    refresh() {
      this.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  display: flex;

  .el-input {
    width: 300px;
  }

  .el-select {
    margin: 0 10px;
  }
}
</style>
